import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const News = () => (
  <Layout>
    <SEO title="News" />

    <section className="section py-8  px-8 pb-9">
      <div className="container-fluid">
        <h5 className="h2 text-uppercase text-underline font-weight-bold text-primary text-center">
          Study in Rwanda orientation
        </h5>
        <div className="py-8 justify-content-between">
          <div className="row row-grid justify-content-center align-items-center">
            <div className="col-lg-10 order-lg-1">
              <p className="h3 font-weight-bold">
                Study in Rwanda in collaboration with the University of Rwanda
                hosted a colorful induction ceremony for international students.
                The event included international students from at least 12
                different nationalities.
              </p>
              <div className="row justify-content-between">
                <div className="col col-md">
                  <figure>
                    <img src={require("./../../images/downloads/RY6B0867.jpg")} alt="Image" className="img-fluid rounded" />
                  </figure>
                </div>
                <div className="col-12 col-md">
                  <figure>
                    <img src={require("./../../images/downloads/RY6B0919.jpg")} alt="Image" className="img-fluid rounded" />
                  </figure>
                </div>
              </div>
              <p className=" my-4">
                In attendance were more than 250 international students from all
                the University of Rwanda colleges. This event brought out a
                clear picture of the bold step taken by Study in Rwanda to
                promote our education system globally.
              </p>
              <p className="my-4">
                Study in Rwanda ensures students are acquainted with their new
                campus life. A study in Rwanda orientation also allows
                international students to learn about school policies, general
                rules etc.
              </p>

              <div className="row justify-content-between">
                <div className="col col-md">
                  <figure>
                    <img src={require("./../../images/downloads/RY6B1015.jpg")} alt="Image" className="img-fluid rounded" />
                  </figure>
                </div>
                <div className="col-12 col-md">
                  <figure>
                    <img src={require("./../../images/downloads/RY6B0758.jpg")} alt="Image" className="img-fluid rounded" />
                  </figure>
                </div>
              </div>
              <p className="my-4">
                After orientation, students participate in other fun activities.
                The students also have an opportunity to students to make
                friends, and become comfortable about navigating the campus.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default News
